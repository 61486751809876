import React, { useState, useEffect } from "react";
import DepositComponent from ".";
import Confirmation from "./confirmation";
import Details from "./details";
import Success from "./Success";
import useDeposit from "../../hooks/useDeposit";
import client from "../../utils/client";

const Deposit = () => {
  const [state, setState] = useState({
    step: 1,
    assetChosen: "",
    networkChosen: "",
    asset: [],
    txnId: "",
    amount: 0,
  });
  const { deposit, loading, error, success } = useDeposit();

  const prevStep = () => {
    const { step } = state;
    setState({ ...state, step: step - 1 });
  };

  const nextStep = () => {
    const { step } = state;
    setState({
      ...state,
      step: step + 1,
    });
  };

  const assetChange = (input) => (e) => {
    setState({ ...state, [input]: e.target.value });
  };

  useEffect(() => {
    async function getAsset() {
      const { data } = await client({ url: `/txns/coins`, method: "GET" });
      const chosenAsset = data.data.filter(
        (coin) => coin.name === state.assetChosen
      );
      setState({ ...state, asset: chosenAsset });
    }
    if (state.assetChosen) {
      getAsset().catch((err) => console.log({ err }));
    }
  }, [state.assetChosen, state]);

  const depositFunds = async () => {
    await deposit(
      state.assetChosen,
      state.networkChosen,
      state.txnId,
      state.amount
    );
  };

  const { step } = state;
  const { assetChosen, networkChosen, amount, asset } = state;
  const values = { networkChosen, assetChosen, amount, asset };
  return (
    <>
      {step === 1 && (
        <DepositComponent
          nextStep={nextStep}
          handleChange={assetChange}
          values={values}
          network1={state.network1}
          network2={state.network2}
          network3={state.network3}
        />
      )}
      {step === 2 && (
        <Confirmation prevStep={prevStep} nextStep={nextStep} values={values} />
      )}
      {step === 3 && <Details values={values} nextStep={nextStep} />}
      {step === 4 && (
        <Success
          handleChange={assetChange}
          submit={depositFunds}
          load={loading}
          success={success}
          error={error}
        />
      )}
    </>
  );
};

export default Deposit;
