import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import SidebarComponent from "../../components/navigation/Sidebar";
import Withdraw from "../../components/withdraw/withdraw";
import Loader from "../../components/Loader";
import DepositHistoryComponent from "../../components/tables/DepositHistory";
import InvestmentTable from "../../components/tables/InvestmentTable";
import DashboardFooter from "../../components/navigation/DashboardFooter";
import client from "../../utils/client";
import TiersModal from "../../components/modals/TiersModal";
import KYCModal from "../../components/modals/KYCModal";
import { Alert, Snackbar } from "@mui/material";
import check from "../../assets/check.svg";

export const userTiers = {
  base: "Tier 1",
  bronze: "Tier 2",
  silver: "Tier 3",
  gold: "Tier 4",
};

const Dashboard = () => {
  const [withdraw, setWithdraw] = useState(false);
  const [realTimeUser, setRealTimeUser] = useState(false);
  const [upgrade, setUpgrade] = useState(false);
  const [investments, setInvestments] = useState([]);
  const [investmentsAmount, setInvestmentsAmount] = useState(0);
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function getInvestments() {
      try {
        const { data } = await client({
          url: `/txns/investment?id=${user._id}`,
          method: "GET",
        });

        setInvestments(data.data);
        const activeInvestments = data.data.filter(
          (investment) => investment.status === "active" && investment.amount
        );
        const investmentAccumulator = activeInvestments
          .map((investment) => investment.amount)
          .reduce((prev, curr) => parseInt(prev) + parseInt(curr), 0);

        setInvestmentsAmount(investmentAccumulator);
      } catch (error) {
        console.log(error);
      }
    }
    getInvestments().catch((err) => console.log({ err }));
  }, [user._id]);

  useEffect(() => {
    client({ url: `/auth/echo?email=${user.email}`, method: "GET" })
      .then((res) => {
        setRealTimeUser(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user.email]);

  return (
    <div>
      <div>
        <Loader />
      </div>
      <div>
        <SidebarComponent user={user} />
      </div>
      {!realTimeUser.verified && <KYCModal />}
      <div className="p-4 lg:ml-64 bg-[#0a0c0f] min-h-screen">
        <div className="p-4 border border-gray-600 border-dashed min-h-[90vh] rounded-lg dark:border-gray-700 mt-20">
          <div className="lg:grid max-lg:space-y-5 grid-cols-3 gap-4 mb-4">
            <div className="rounded bg-[#181b20] p-5">
              <h1 className="text-2xl font-medium text-gray-300">Balance</h1>
              <p className="mt-2 text-lg text-gray-400">
                ${realTimeUser.balance}
              </p>
            </div>
            <div className="rounded bg-[#181b20] p-5">
              <h1 className="text-2xl font-medium text-gray-300">
                Investments
              </h1>
              <div className="mt-3 flex items-center justify-between">
                <div>
                  <h2 className="text-gray-400 font-normal text-lg">Active</h2>
                  <p className="mt-1 text-lg text-gray-400">
                    {realTimeUser.activeInvestments}
                  </p>
                </div>
                <div>
                  <h2 className="text-gray-400 font-normal text-lg">Total</h2>
                  <p className="mt-2 text-lg text-gray-400">
                    ${investmentsAmount}
                  </p>
                </div>
              </div>
            </div>
            <div className="rounded bg-[#181b20] p-5">
              <div className="flex items-center justify-between">
                <h1 className="text-2xl font-medium text-gray-300">
                  Withdrawals
                </h1>
                <button
                  onClick={() => {
                    setWithdraw(!withdraw);
                  }}
                  className="w-24 text-center bg-[#b2ffce] text-sm rounded-md px-2 py-1 outline-none"
                >
                  Withdraw
                </button>
              </div>
              {withdraw && (
                <div className="z-50 relative">
                  <Withdraw />
                </div>
              )}
              <div className="mt-3 flex items-center justify-between">
                <div>
                  <h2 className="text-gray-400 font-normal text-lg">
                    Requests
                  </h2>
                  <p className="mt-1 text-lg text-gray-400">
                    {realTimeUser.withdrawals}
                  </p>
                </div>
                <div>
                  <h2 className="text-gray-400 font-normal text-lg">Total</h2>
                  <p className="mt-2 text-lg text-gray-400">
                    ${realTimeUser.withdrawn}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-4 p-5 rounded bg-[#181b20]">
            <h1 className="text-gray-300 mb-5 font-medium text-xl">
              Finish setting up your account
            </h1>
            <div className="sm:flex space-y-3 md:space-x-4 sm:space-y-0 justify-evenly">
              <Link to="/kyc">
                <div className="border-[0.5px] border-gray-600 rounded p-3 lg:p-5 relative">
                  <div className="flex items-center space-x-3 sm:justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 text-gray-500"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>

                    <div>
                      <h2 className="text-gray-300 text-sm">
                        Verify your account
                      </h2>
                      <p className="text-gray-400 text-xs">
                        Verify your identity by providing some credentials to
                        secure your account
                      </p>
                    </div>
                  </div>
                  {realTimeUser.verified && (
                    <img
                      src={check}
                      className="absolute top-2 right-2"
                      width={16}
                      height={16}
                      alt=""
                    />
                  )}
                </div>
              </Link>
              <div
                className="border-[0.5px] border-gray-600 rounded p-3 lg:p-5 hover:cursor-pointer relative"
                onClick={() => {
                  if (!realTimeUser.verified) {
                    alert("Please verify your account before depositing!");
                  } else {
                    navigate("/deposit");
                  }
                }}
              >
                <div className="flex items-center space-x-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 text-gray-500"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                    />
                  </svg>

                  <div>
                    <h2 className="text-gray-300 text-sm">Deposit Funds</h2>
                    <p className="text-gray-400 text-xs">
                      Deposit crypto to invest in strategies on Algoo Strategies
                    </p>
                  </div>
                </div>
                {realTimeUser.balance > 0 && (
                  <img
                    src={check}
                    className="absolute top-2 right-2"
                    width={16}
                    height={16}
                    alt=""
                  />
                )}
              </div>
              <Link to="/dashboard/strategies">
                <div className="border-[0.5px] border-gray-600 rounded p-3 lg:p-5 hover:cursor-pointer relative">
                  <div className="flex items-center space-x-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-10 h-10 text-gray-500"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>

                    <div>
                      <h2 className="text-gray-300 text-sm">
                        Invest in strategies
                      </h2>
                      <p className="text-gray-400 text-xs">
                        Start your investment journey with the available
                        strategies on Algoo Strategies
                      </p>
                    </div>
                  </div>
                  {investments.length > 0 && (
                    <img
                      src={check}
                      className="absolute top-2 right-2"
                      width={16}
                      height={16}
                      alt=""
                    />
                  )}
                </div>
              </Link>
              {realTimeUser._type !== userTiers.gold && (
                <div
                  onClick={() => {
                    if (!realTimeUser.verified) {
                      <Snackbar open={true} autoHideDuration={5000}>
                        <Alert severity="error">
                          Please verify your account before depositing!
                        </Alert>
                      </Snackbar>;
                    } else {
                      setUpgrade(!upgrade);
                    }
                  }}
                  className="border-[0.5px] border-gray-600 rounded p-3 lg:p-5 hover:cursor-pointer"
                >
                  <div className="flex items-center space-x-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6 text-gray-500"
                    >
                      <path
                        fillRule="evenodd"
                        d="M11.47 2.47a.75.75 0 011.06 0l7.5 7.5a.75.75 0 11-1.06 1.06l-6.22-6.22V21a.75.75 0 01-1.5 0V4.81l-6.22 6.22a.75.75 0 11-1.06-1.06l7.5-7.5z"
                        clipRule="evenodd"
                      />
                    </svg>

                    <div>
                      <h2 className="text-gray-300 text-sm">Upgrade Account</h2>
                      <p className="text-gray-400 text-sm">
                        Deposit funds to upgrade your account. Your current tier
                        is {realTimeUser._type}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {upgrade && <TiersModal userTier={realTimeUser._type} />}
          <div className="md:grid space-y-5 md:space-y-0 grid-cols-2 gap-4 mb-4">
            <div className="flex items-center justify-between rounded bg-[#181b20] p-5">
              <div>
                <h1 className="text-2xl font-medium text-gray-300">Earnings</h1>
                <p className="mt-2 text-lg text-gray-400">
                  ${realTimeUser.earnings}
                </p>
              </div>
              <div>
                <h2 className="text-gray-400 font-normal text-lg">
                  Management Fee
                </h2>
                <p className="mt-2 text-lg text-gray-400">
                  ${realTimeUser.managementFee}
                </p>
              </div>
            </div>
            <div className="rounded bg-[#181b20] px-5 py-5">
              <div className="flex items-center justify-between">
                <h1 className="text-2xl font-medium text-gray-300">Deposits</h1>
                <button
                  onClick={() => {
                    if (!realTimeUser.verified) {
                      alert("Please verify your account before depositing!");
                    } else {
                      navigate("/deposit");
                    }
                  }}
                  className="w-24 text-center bg-[#b2ffce] rounded-md px-4 py-1 outline-none"
                >
                  Deposit
                </button>
              </div>
              {/* {deposit && <Deposit />} */}
              <div className="mt-3 flex items-center justify-between">
                <div>
                  <h2 className="text-gray-400 font-normal text-lg">
                    Deposited
                  </h2>
                  <p className="mt-1 text-lg text-gray-400">
                    {realTimeUser.deposits}
                  </p>
                </div>
                <div>
                  <h2 className="text-gray-400 font-normal text-lg">Total</h2>
                  <p className="mt-2 text-lg text-gray-400">
                    ${realTimeUser.deposited}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className=" mb-4 rounded bg-[#181b20] p-5">
            <h1 className="font-medium text-3xl mb-4 text-gray-300">
              Deposits
            </h1>
            <div>
              <DepositHistoryComponent />
            </div>
          </div>
          <div className=" mb-4 rounded bg-[#181b20] p-5">
            <h1 className="font-medium text-3xl mb-4 text-gray-300">
              Investments
            </h1>
            <div>
              <InvestmentTable />
            </div>
          </div>
          <DashboardFooter />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
