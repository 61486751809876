import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../context/authContext";
import client from "../../utils/client";
import truncateText from "../../utils/truncate-text";
import Table from "./Table";

const InvestmentTable = () => {
  const [investments, setInvestments] = useState([]);
  const { user, token } = useContext(AuthContext);

  async function deactivateInvestments(docId) {
    try {
      client({
        url: `/pool/deactivate`,
        data: { investmentId: docId },
        headers: {
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
      })
        .then((res) => {
          alert(res.data.message);
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    async function getInvestments() {
      try {
        const { data } = await client({
          url: `/txns/investment?id=${user._id}`,
          method: "GET",
        });
        setInvestments(data.data);
      } catch (error) {
        console.log(error);
      }
    }

    getInvestments().catch((err) => console.log({ err }));
  }, [user._id]);

  return (
    <div>
      {investments.length > 0 ? (
        <Table
          columns={[
            {
              title: "Strategy Name",
              render: (data) => <h1 className="light-grey">{data.pool}</h1>,
            },
            {
              title: "Amount",
              render: (data) => <h1 className="light-grey">{data.amount}</h1>,
            },
            {
              title: "Status",
              render: (data) => <h1 className="light-grey">{data.status}</h1>,
            },
            {
              title: "Entry",
              render: (data) => <h1 className="light-grey">{data.entry}</h1>,
            },
            {
              title: "Exit",
              render: (data) => (
                <h1 className="light-grey">
                  {data.exit === "null" ? "-" : data.exit}
                </h1>
              ),
            },
            {
              title: "Date",
              render: (data) => (
                <h1 className="light-grey">
                  {truncateText(data.createdAt, 10)}
                </h1>
              ),
            },
            {
              title: "",
              render: (data) =>
                data.status === "active" && (
                  <button
                    onClick={() => {
                      deactivateInvestments(data._id);
                    }}
                    className="py-2 px-3 text-sm max-md:my-3 max-md:w-full text-white bg-red-700 rounded-lg font-medium"
                  >
                    Deactivate
                  </button>
                ),
            },
          ]}
          data={investments}
        />
      ) : (
        <div>
          <p className="text-center text-gray-300">No available data</p>
        </div>
      )}
    </div>
  );
};

export default InvestmentTable;
