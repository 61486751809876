import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";

const Details = ({ values, nextStep }) => {
  const [wallet, setWallet] = useState("");
  const [copied, setCopied] = useState(false);

  const nextPage = (e) => {
    e.preventDefault();
    nextStep();
  };

  function copyWallet() {
    navigator.clipboard.writeText(wallet);
    setCopied(true);
  }
  useEffect(() => {
    values?.asset.forEach((coin) => {
      coin?.wallets.forEach((coinWallet) => {
        if (coinWallet.network === values.networkChosen) {
          setWallet(coinWallet.walletAddress);
        }
      });
    });
  }, [values.asset, values.networkChosen]);
  return (
    <div>
      <div
        id="defaultModal"
        tabIndex="-1"
        aria-hidden="true"
        className="w-full p-1 md:p-4"
      >
        <div className="top-10 lg:top-20 max-w-xl max-lg:mx-auto md:h-auto">
          <div className="">
            <div className="p-4 rounded-t">
              <h3 className="text-xl font-semibold text-gray-300">Deposit</h3>
            </div>
            <div className="lg:px-6 py-5 text-gray-400">
              <div className="md:flex items-center justify-between max-md:space-y-4 md:space-x-2">
                <div>
                  <h1>Selected Asset</h1>
                  <p className="font-semibold uppercase text-sm">
                    {values?.assetChosen}
                  </p>
                </div>
                <div>
                  <h1>Selected Network</h1>
                  <p className="font-semibold uppercase text-sm">
                    {values?.networkChosen ? values?.networkChosen : "none"}
                  </p>
                </div>
              </div>
              <div>
                <div className="bg-white p-1 mx-auto w-24 mt-7">
                  <QRCode
                    className="w-20 mx-auto"
                    size={20}
                    style={{
                      height: "auto",
                    }}
                    value={wallet}
                    viewBox={`0 0 256 256`}
                  />
                </div>
                <p className="mt-2 text-sm max-w-sm mx-auto text-center">
                  Sending crypto to the wrong address or on the wrong network
                  will result in lost funds. Please verify your deposit details.
                </p>
              </div>
              <div className="mt-9 space-y-4">
                <div className="my-5">
                  <h1>Wallet Address: </h1>
                  <div className="flex items-center justify-between mt-2">
                    <p className="break-all w-full max-[500px]:mb-2">
                      {wallet}
                    </p>
                    <div className="relative">
                      {copied && (
                        <div className="absolute bg-gray-500 text-gray-900 -top-8 -left-8 rounded-md text-xs py-1 px-2">
                          Copied!
                        </div>
                      )}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        onClick={copyWallet}
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 text-gray-400 hover:cursor-pointer"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center py-4 space-x-2 rounded-b">
              <button
                data-modal-hide="defaultModal"
                type="button"
                onClick={nextPage}
                className="bg-[#b2ffce] rounded-md text-sm px-5 py-2 text-center"
              >
                I have deposited the funds
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Details;
